<template>
  <div class="sub-cont">
    <div class="mypage-wrap">
      <h2 class="h2-mypage">자기소개서</h2>
      <textarea
        v-model="introduceText"
        name=""
        id=""
        cols="30"
        rows="10"
      ></textarea>
      <p class="textarea-info">({{ wordCount }} 자)</p>
    </div>
  </div>
</template>

<script>
import { getIntroduceInfo, saveIntroduceInfo } from 'Api/modules'
export default {
  data() {
    return {
      introduceText: '',
      wordCount: 0,
    }
  },
  watch: {
    introduceText(newVal) {
      this.wordCount = this.byteLength(newVal)
    },
  },
  methods: {
    byteLength(str) {
      let count = 0
      let ch = ''
      for (let i = 0; i < str.length; i++) {
        ch = str.charAt(i)
        if (escape(ch).length === 6) {
          count++
        }
        count++
      }
      return count
    },
    validateInput(data) {
      let ok = true
      for (let value of Object.values(data)) {
        if (value === '' || value === null) {
          ok = false
          break
        }
      }
      return ok
    },
    async save() {
      console.info('자기소개', this.introduceText)
      const saveData = { selfIntroduction: this.introduceText }

      //입력 오류
      if (!this.validateInput(saveData)) return false

      try {
        await saveIntroduceInfo(saveData)
        this.$toasted.show('이력서가 저장되었습니다')
        // this.isSaved = true
        // this.SET_EDITING_DOCUMENT_NO(res.documentNo)
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
  },
  async created() {
    // 이력서 조회
    const res = await getIntroduceInfo()
    this.introduceText = res.selfIntroduction
  },
}
</script>

<style></style>
